import { colors, styled } from "@mui/material";

export const RegistrationFormBox = styled("section")(({ theme }) => ({
  "& .title_holder": {
    marginBottom: "1rem",

    "& h1": {
      fontSize: "1.25rem",
      fontWeight: 600,
      color: theme.palette.secondary.main
    },
  },

  "& .dangerText": {
    color: "#F71C1C",
    fontSize: "0.875rem",
    fontWeight: 600
  },

  "& .registrationCard": {
    width: "100%",
    maxWidth: "600px",
    margin: "1rem auto",
    padding: "2rem 3rem",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",

    "& .details": {
      color: "#424242",
      paddingBottom: "1rem",
    },
    "& .paid-instructions": {
      display: "flex",
      color: "#1280bb",
      padding: "1rem 0",
    },
  },

  "& .registrationForm": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    paddingBottom: "100px",
  },

  "& .cLabel": {
    fontSize: "0.75rem",
    color: "#424242",
    marginBottom: "0.25rem"
  },

  "& .uploadContainer": {

    "& .fileInput": {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: "100%",
      opacity: 0,
      filter: 'alpha(opacity=0)'
    },

    "& .previewContainer": {
      position: 'relative !important',
      zIndex: 2
    },

    "& .cancelButton": {
      padding: '0.25rem !important',

    },

    "& .uploadHolder": {
      cursor: "pointer",
      border: "1px solid #C9C9C9",
      height: "50px",
      borderRadius: "8px",
      padding: "0 1rem",
      fontSize: "0.865rem",
      color: "#424242",
      position: "relative",
      overflow: "hidden",
    },

    "& .errorBorder": {
      borderColor: "#F71C1C"
    },

    "& .redTextInfo": {
      color: "#F71C1C",
      fontSize: "0.75rem",
      fontWeight: 600
    }

  },

  "& .form-inputs": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    marginBottom: "50px",
  },

  "& .eventTypeTag": {
    padding: "0.9rem 2rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    background: "#CEF7F7",
    color: "#0C0C0C",
    fontWeight: 600,
    fontSize: "1.05rem",
    margin: "0.5rem 0 2rem",
  },

  "& .navContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "2rem 0 4rem",

    "& .disabledElement": {
      "& svg": {
        cursor: "pointer",

        "& path": {
          fill: "#E4E4E4",
        },
      },
    },
  },

  "& .previewTier": {
    padding: "1rem 1.5rem",
    margin: "1.5rem 0",
    border: "1px solid #E4E4E4",

    "& .header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& .title": {
        marginBottom: "0",
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: "1.15rem",
      },

      "& .editBtn": {
        color: "#F71C1C",
        fontWeight: 400,
        fontSize: "0.875rem",
        textDecoration: "underline",
        cursor: "pointer",
      },

      "& .price": {
        color: theme.palette.secondary.main,
        fontWeight: 600,
        fontSize: "2.5rem",
      },
    },

    "& .header-details": {
      color: "#7b7b7b !important",
      paddingBottom: 0,
      fontWeight: 500,
      fontSize: "0.875rem",
    },

    "& .details": {
      color: "#424242",
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },

  "& .scheduleTitle": {
    width: "100%",
    padding: "1rem 1rem",
    border: "1px solid #C9C9C9",
    borderRadius: "0.25rem",
    fontWeight: 400,
    fontSize: "0.875rem",
    color: "#939393",
  },

  "& .oldPrice": {
    color: "#787878",
    fontSize: "0.875rem",
  },

  "& .loadingText":{
    fontSize: "0.875rem",
  },

  "& .verifiedText":{
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginTop: "-0.5rem"
  },

  // "& .MuiMenuItem-root": {
  //   "& .scheduleHolder": {

  //     "& .day": {

  //     },

  //     "& .date": {

  //     },
  //   },
  // },

  "@media screen and (max-width: 767.9px)": {
    "& .registrationCard": {
      padding: "1.5rem 1rem",

      "& h1": {
        fontSize: "1.15rem",
      },

      "& .details": {
        fontSize: "0.9rem",
      },
    },

    "& .form-inputs": {
      marginBottom: 0,
    },

    "& .eventTypeTag": {
      padding: "0.75rem 1rem",
      fontWeight: 600,
      fontSize: "0.875rem",
      margin: "0.5rem 0 1rem",
    },

    "& .navContainer": {
      margin: "0.5rem 0 1.5rem",

      "& svg": {
        minWidth: "25px",
        minHeight: "25px",
        width: "25px",
        height: "25px",
      },

      "& span": {
        fontSize: "0.875rem",
      },
    },

    "& .previewTier": {
      padding: "0.75rem",
      margin: "1rem 0",

      "& .header": {
        "& .title": {
          fontSize: "1rem",
        },

        "& .price": {
          color: theme.palette.secondary.main,
          fontWeight: 600,
          fontSize: "2.25rem",

          "& svg": {
            width: "22px",
          },
        },
      },

      "& .details": {
        fontSize: "0.9rem",
        paddingBottom: 0,
      },
    },

    "& .loadingText":{
      fontSize: "0.75rem",
    },

    "& .verifiedText":{
      fontSize: "0.875rem",
    },
  },
}));
